<template>
  <va-card title="Letters">
    <va-data-table
      :fields="fields"
      :data="items"
      :per-page="50"
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          icon="fa fa-eye"
          small
          color="info"
          :to="{ name: '1lk-letters-view', params: { id: props.rowData.id } }"
        >
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [{
        name: 'date_create',
        title: 'Created',
        width: '15%',
      },
      {
        name: 'date_update',
        title: 'Updated',
        width: '15%',
      },
      {
        name: 'subject',
        title: 'Subject',
        width: '15%',
      },
      {
        name: 'status_name',
        title: 'Status',
        width: '10%',
      },
      {
        name: 'from',
        title: 'From',
        width: '15%',
      },
      {
        name: 'to',
        title: 'To',
        width: '15%',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/letter?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.forEach((item) => {
            const date = new Date(item.date_create * 1000)
            item.date_create = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
            const date2 = new Date(item.date_update * 1000)
            item.date_update = item.date_update ? date2.toLocaleDateString('uk-UA') + ' ' + date2.toLocaleTimeString('uk-UA') : ''
            const rec = JSON.parse(item.recipients)
            item.from = rec.from
            item.to = rec.to.join('\r\n')
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
